/* global $ */
class Tours
{
    constructor() {
        this.filters = $('.filters');
        this.tours = $('.tour');
        this.showTours();
        this.initFilters();
        console.log('Tours initialized');
    }

    initFilters()
    {
        this.filters.find('select').change(() => {
            this.showTours();
        }); 
    }

    showTours()
    {
        let id = this.filters.find('select.name').val();
        let activity = this.filters.find('select.activity').val();
        let duration = this.filters.find('select.duration').val();
        let toShow = this.tours.filter((i, tour) => {
            if (activity && !$(tour).data('activities').includes(parseInt(activity))) {
                return false;
            }
            if (id && $(tour).data('name') != id) {
                return false;
            }
            if (duration && !$(tour).data('duration').includes(parseInt(duration))) {
                return false;
            }
            return true;
        });
        let visible = this.tours.filter((i, item) => {
            return $(item).is(':visible');
        });
        if (visible.length) {
            visible.fadeOut(200, () => {
                toShow.fadeIn(200);
            });
        } else {
            toShow.fadeIn(200);
        }
    }
}

export default Tours;
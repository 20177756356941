/* global formidable*/
import $ from 'jquery';

class Form
{
    constructor(elem, messages) {
        this.$form = elem;
        this.$messages = messages;
        if (this.$form.length) {
            this.id = this.$form.attr('id');
            this.initFormSubmit();
            console.log('Form ' + this.id + ' initialized');
        }
    }

    initFormSubmit() {
        let _this = this;
        this.$form.off('submit');
        this.$form.submit(function(e) {
            e.preventDefault();
            _this.hideSuccess();
            _this.removeErrors();
            if (formidable.validateForm(this, e)) {
                _this.disableSubmit();
                let url = $(this).attr('action');
                let formData = new FormData(this);
                $.ajax({
                    url: url,
                    data: formData,
                    contentType: false,
                    processData: false,
                    method: 'POST',
                    dataType: 'JSON'
                }).done((data) => {
                    if (data.success) {
                        _this.showSuccess();
                        _this.reset();
                    }
                }).fail((response) => {
                    if (response.responseJSON.errors) {
                        _this.addErrors(response.responseJSON.errors);
                        _this.scrollToMessages();
                    }
                }).always(() => {
                    _this.reloadCaptcha();
                    _this.enableSubmit();
                });
            }
        });
    }

    reset()
    {
        this.$form.trigger('reset');
    }

    enableSubmit()
    {
        this.$form.find('[type=submit]').attr('disabled', false);
    }

    disableSubmit()
    {
        this.$form.find('[type=submit]').attr('disabled', true);
    }

    reloadCaptcha()
    {
        window.googleV3Captcha.getCaptcha(this.id).then((token) => {
            this.$form.find('[name=_recaptcha_response]').val(token);
        });
    }

    removeErrors()
    {
        if (this.$messages.length) {
            this.$messages.find('alert-danger').remove();
        }
    }

    addErrors(errors)
    {
        if (this.$messages.length) {
            for (let i in errors) {
                let errors = errors[i];
                for (let j in errors) {
                    $('<div class="alert alert-danger">'+errors[j]+'</div>').appendTo(this.$messages);
                }
            }
        }
    }

    scrollToMessages()
    {
        if (this.$messages.length) {
            $('html, body').animate({ scrollTop: this.$messages.offset().top - 170 }, 500);
        }
    }

    hideSuccess()
    {
        if (this.$messages.length) {
            this.$messages.find('alert-success').hide();
        }
    }

    showSuccess()
    {
        if (this.$messages.length) {
            let success = this.$messages.find('.alert-success');
            if (success.length) {
                success.fadeIn();
                $('html, body').animate({ scrollTop: success.offset().top - 170 }, 500);
            }
        }
    }
}

export default Form;
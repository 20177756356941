/**
 * Import here any npm modules and your own js/scss
 * You can import npm modules as css, scss or js
 * By importing scss you give yourself the ability to override the variables through resources.scss
 */

/**************
 * Javascript
 **************/

//Npm Libraries
import 'jquery';
import 'bootstrap';
import 'slick-carousel';
import 'lazysizes';

//App
import './js/app.js';

/**************
 * Css
 **************/

//Npm Libraries
import "bootstrap/scss/bootstrap.scss";
import 'slick-carousel/slick/slick-theme.scss';
import 'slick-carousel/slick/slick.scss';
import 'font-awesome/css/font-awesome.css';

//App
//Front theme
import "./css/app/base/mixins.scss";
import "./css/app/base/html.scss";
import "./css/app/base/buttons.scss";
import "./css/app/base/typography.scss";
import "./css/app/components/home.scss";
import "./css/app/components/slick.scss";
import "./css/app/components/header.scss";
import "./css/app/components/footer.scss";
import "./css/app/components/nav.scss";
import "./css/app/components/cta-full.scss";
import "./css/app/components/breadcrumb.scss";
import "./css/app/components/forms.scss";
import "./css/app/components/team.scss";
import "./css/app/components/news.scss";
import "./css/app/components/events.scss";
import "./css/app/components/activities.scss";
import "./css/app/components/contact.scss";
import "./css/app/components/standard.scss";
import "./css/app/components/tripadvisor.scss";
import "./css/app/components/indiv-activities.scss";
import "./css/app/components/gallery.scss";
import "./css/app/components/groups.scss";
import "./css/app/components/tours.scss";


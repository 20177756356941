/* global $ */
class Tour
{
    constructor(scrollTo) {
        if (scrollTo) {
            let scrollId = '#tour-option-' + scrollTo;
            if ($(scrollId).length) {
                $('html, body').animate({ scrollTop: $(scrollId).offset().top - 120 }, 500);
            }
        }
        console.log('Tour initialized');
    }
}

export default Tour;
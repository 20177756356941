/* global $ */
class Home {
    constructor() {
        this.initStickyMenu();
        console.log('Home initializedd');
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 250) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function () {
            stickyNav();
        });

        $(".navbar-toggler").click(function () {
            $('body').toggleClass("menuOpen");
        });

        $(".standard-content iframe").parent("figure").addClass('video-responsive');

    }

}

export default Home;
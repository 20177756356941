/* global $ */
class News
{
    constructor(category)
    {
        this.offset = 3;
        this.limit = 1;
        this.hasMoreNews = true;
        this.isLoading = false;
        this.category = category;
        this.form = $('.category-form');
        this.list = $('.newsLists');
        this.initForm();
        this.initScroll();
        console.log('News initialized');
    }

    initScroll()
    {
        $(window).scroll(() => {
            let bottom = this.list.offset().top + this.list.outerHeight() + 50;
            let windowBottom = $(window).height() + $(window).scrollTop();
            if (windowBottom > bottom) {
                this.fetchNews();
            }
        });
    }

    initForm()
    {
        this.form.find('select').change(() => {
            this.form.submit();
        });
    }

    fetchNews()
    {
        if (this.hasMoreNews && !this.isLoading) {
            this.isLoading = true;
            $.ajax({
                url: '/loadNews',
                method: 'get',
                data: {offset: this.offset, limit: this.limit, category: this.category },
            }).done((result) => {
                let data = $(result).hide().fadeIn("slow");
                let count = data.filter('.newsList').length;
                $('.newsLists').append(data);
                this.offset += count;
                if (count !== this.limit ) {
                    this.hasMoreNews = false;
                }                
            }).always(() => {
                this.isLoading = false;
            })
        }
    }
}

export default News;
/* global $ */
class Slick
{
    constructor() {
        this.initCarousel();
        this.initGalleryCarousel();
        console.log('Slick initialized');
        $('.slick-carousel').slick({
            autoplay: true,
            arrows: false,
            speed: 1000,
            autoplaySpeed: 2000,
            dots: true,
            fade: true,
        });
    }

    initCarousel() {
        $('.otherEvents-carousel').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });

        $('.latestArticles-carousel').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });

        $('.otherActivies-carousel').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 374,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        });

    }



    initGalleryCarousel()
    {
        $('.gallery-carousel').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.gallery-carousel-small',
        });

        $('.gallery-carousel-small').slick({
            autoplay: false,
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            speed: 1000,
            autoplaySpeed: 4000,
            asNavFor: '.gallery-carousel',
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                
            ]
        });

        $('.footer-logos').slick({
            autoplay: true,
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 8,
            centerMode: false,
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 1439,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
            ]
        });

    }



}

export default Slick;
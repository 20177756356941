/* global $ */
import Home from './components/home';
import Slick from './components/slick';
import News from './components/news';
import Tours from './components/tours';
import Tour from './components/tour';
import Courses from './components/courses';
import Form from './components/form';
import Events from './components/events';

window.Tours = Tours;
window.Tour = Tour;
window.Courses = Courses;
window.News = News;
window.Events = Events;
window.Form = Form;

$(function() {
    window.Home = new Home();
    new Slick();
});

/* global $ */
class Courses
{
    constructor() {
        this.filters = $('.filters');
        this.courses = $('.course');
        this.showCourses();
        this.initFilters();
        console.log('Courses initialized');
    }

    initFilters()
    {
        this.filters.find('select').change(() => {
            this.showCourses();
        }); 
    }

    showCourses()
    {
        let id = this.filters.find('select.name').val();
        let activity = this.filters.find('select.activity').val();
        let duration = this.filters.find('select.duration').val();
        let level = this.filters.find('select.level').val();
        let age = this.filters.find('select.age').val();
        let bca = this.filters.find('select.bca').val();
        let toShow = this.courses.filter((i, tour) => {
            if (activity && !$(tour).data('activities').includes(parseInt(activity))) {
                return false;
            }
            if (age && !$(tour).data('age').includes(parseInt(age))) {
                return false;
            }
            if (id && $(tour).data('name') != id) {
                return false;
            }
            if (level && $(tour).data('level') != level) {
                return false;
            }
            if (bca && $(tour).data('bca') != bca) {
                return false;
            }
            if (duration && !$(tour).data('duration').includes(parseInt(duration))) {
                return false;
            }
            return true;
        });
        let visible = this.courses.filter((i, item) => {
            return $(item).is(':visible');
        });
        if (visible.length) {
            visible.fadeOut(200, () => {
                toShow.fadeIn(200);
            });
        } else {
            toShow.fadeIn(200);
        }
    }
}

export default Courses;
/* global $ */
class Events
{
    constructor(category)
    {
        this.offset = 6;
        this.limit = 3;
        this.hasMoreEvents = true;
        this.isLoading = false;
        this.category = category;
        this.skeleton = $('.skeleton');
        this.list = $('.eventList');
        this.form = $('.events-form');
        this.initForm();
        this.initScroll();
        this.initCarousel();
        console.log('Events initialized');
    }

    initCarousel()
    {
        $('.calendar-carousel').slick({
            autoplay: false,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false
        });
        $('.calendar-carousel .calendar-title .slick-prev').click(() => $('.calendar-carousel').slick('prev'));
        $('.calendar-carousel .calendar-title .slick-next').click(() => $('.calendar-carousel').slick('next'));
    }

    initForm()
    {
        this.form.find('select').change(() => {
            this.form.submit();
        });
    }

    initScroll()
    {
        $(window).scroll(() => {
            let bottom = this.list.offset().top + this.list.outerHeight();
            let windowBottom = $(window).height() + $(window).scrollTop();
            if (windowBottom > bottom) {
                this.fetchEvents();
            }
        });
    }

    fetchEvents()
    {
        if (this.hasMoreEvents && !this.isLoading) {
            this.isLoading = true;
            $.ajax({
                type: 'get',
                url: '/loadEvents',
                dataType: 'json',
                data: {offset: this.offset, limit: this.limit, category: this.category },
                processData: true,
            }).done((result) => {
                if (result.length) {
                    this.displayEvents(result);
                    this.offset += result.length;
                }
                if (result.length !== this.limit ) {
                    this.hasMoreEvents = false;
                }
            }).always(() => {
                this.isLoading = false;
            });
        }
    }

    displayEvents(results)
    {
        for (let i in results) {
            let data = results[i];
            let event = this.skeleton.clone().removeClass('d-none').removeClass('skeleton').hide();
            event.find('h3').html(data.title);
            event.find('.date').html(data.date);
            event.find('.time').html(data.time);
            event.find('.age').html(data.ages);
            event.find('.price').html(data.price);
            if (data.canBook) {
                event.find('.book').attr('href', data.book);
            } else {
                event.find('.book').hide();
            }
            event.find('img').attr('srcset', data.srcset);
            event.find('.link').attr('href', data.link);
            if (data.linklabel) {
                event.find('.book').html(data.linkLabel);
            }
            this.list.append(event);
            event.fadeIn('fast');
        }
    }
}

export default Events;